<template>
  <div>
    <v-row align="center" justify="center">
      <v-col style="margin-top: 15%; margin-bottom: 22%" cols="12">
        <v-progress-circular
          :size="110"
          :width="8"
          color="#fff"
          indeterminate
        ></v-progress-circular>
        <p style="color: #fff" class="mt-5">Cargando...</p>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { ROL } from "./../../utils/util";
import { login } from "./../../utils/auth";
import { mapState, mapMutations } from "vuex";
import Api from "@/utils/api";
import alert from "@/mixins/alert";
export default {
  mixins: [alert],
  data() {
    return {

    };
  },
  computed: {
    ...mapState(["infoUser", "roleName"])
  },
  methods: {
    ...mapMutations(["setInfoUser", "setRoleName"]),
    validateToken() {
      // validación de token
      var token = this.$route.query.token;
      console.log(this.$route)
      var data = {
        jwt: token,
      };

        Api.Auth().validLogin(data)
          .then((res) => {
            if (res.data.cod == 0) {
              login(res.data.data.access_token);
              let encodeUserRole = btoa("userRole");
              let encodeUserRoleName = btoa("userRoleName");
              let encodeValueUserRole = btoa(res.data.data.rol_id);
              let encodeValueUserRoleName = btoa(res.data.data.rol_name);
              localStorage.setItem(encodeUserRole, encodeValueUserRole);
              localStorage.setItem(encodeUserRoleName, encodeValueUserRoleName);
              this.setInfoUser(res.data.data.rol_id);
              this.setRoleName(res.data.data.rol_name);
              this.redirect(res.data.data.rol_id);
              this.alert(res.data.message, "Muy bien", "success");
            } else {
              this.alert(res.data.message, "Oops...", "error");
            }
          })
          .catch((error) => {
              this.alert(error.response.data.message, "Oops...", "error");
              if(error.response.data.cod == 1005){
                  window.location = process.env.VUE_APP_REDIRECT_OKAN;
              }
            
          })
    },
    redirect(rolId) {
      switch (rolId) {
        case ROL.workforce:
          this.$router.push({ name: "listCampaign" });
          break;
        case ROL.directory:
          this.$router.push({ name: "commissionsDirectory" });
          break;
        case ROL.manager:
          this.$router.push({ name: "commissionsManager" });
          break;
        case ROL.payRollManager:
          this.$router.push({ name: "commissionsPayRollManager" });
          break;
        case ROL.coordinator:
          this.$router.push({ name: "commissionsCoordinator" });
          break;
        case ROL.accountManager:
          this.$router.push({ name: "commissionsAccountManager" });
          break;
        default:
          break;
      }
    },
  },
  mounted() {
    this.validateToken();
  },
};
</script>
